import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import driveInFirebase from './helpers/firebase';

import { Button } from '@progress/kendo-react-buttons';
import { Loader } from "@progress/kendo-react-indicators";

import ford_drive_in_logo from './images/ford_drive_in.svg';
import of_the from './images/of_the.svg';
import bronco_theater from './images/bronco_theater.svg';

import './Home.scss';

const allowedTickets = 400;

function Home() {
  let navigate = useNavigate();
  const [totalTickets, setTotalTickets] = useState(null);

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      // sign in again just in case
      await driveInFirebase.signIn();

      const total = await driveInFirebase.getCount();
      setTotalTickets(total);
    }
  
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [])

  return (
    <div className='wrapper'>
      <div className="content">
        <img src={ford_drive_in_logo} className="logo" alt="Ford Drive-In" />
        <div className="double-wide">
          <div className='video'>
            <div className='video-inside'>
              <iframe className='video-player' src="https://www.youtube.com/embed/_d6pE7Xcmq8?rel=0&feature=youtu.be" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"></iframe>
            </div>
          </div>
          <div className='event-content'>

            <div className='blue-table'>
              FORD PRESENTS
            </div>

            <div className='blue-table'>
              EDGE <img src={of_the} alt="of the" className='and-home' /> UNKNOWN
            </div>

            <div className='blue-table'>
              WITH JIMMY CHIN
            </div>

            <div className='blue-table dark-words'>
              + Q&amp;A WITH JIMMY CHIN AND OTHERS
            </div>

            <div className='blue-table dark-words'>
              JACKSON, WYOMING
            </div>

            <div className='lightning-theater'>
              <img src={bronco_theater} alt="Bronco Theater" />
            </div>

            <div className='address'>
              1200 N. Spring Gulch Road <span className='no-wrap'>JACKSON, WY 83001</span><br />
            </div>
            <div className='time'>
              <div className='time-text'>
                AUG 20, 2022
              </div>
              <div className='arrow-wrapper'>
                <span className='arrow-right'></span>
              </div>
              <div className='time-text'>
                DOORS AT 5PM
              </div>
            </div>

            {
              totalTickets === null &&
              <div className='loader'>
                <Loader size="large" type='infinite-spinner' />
              </div>
            }

            {
              totalTickets !== null && totalTickets < allowedTickets &&
              <Button className='get-tickets' rounded={null} onClick={() => navigate('/get-tickets')}>
                GET YOUR FREE TICKET
              </Button>
            }
            {
              totalTickets !== null && totalTickets >= allowedTickets &&
              <div className='get-tickets'>
                SOLD OUT
              </div>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
