import React, { useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import {debounce} from 'lodash';
import driveInFirebase from './helpers/firebase';

import { Button } from '@progress/kendo-react-buttons';
import {
  Form,
  Field,
  FormElement,
  FormRenderProps,
  FieldWrapper,
} from "@progress/kendo-react-form";
import { Error, Label } from "@progress/kendo-react-labels";
import { Checkbox, Input, MaskedTextBox } from "@progress/kendo-react-inputs";
import { getter } from "@progress/kendo-react-common";

import ford_drive_in_logo from './images/ford_drive_in.svg';
import poster from './images/poster.jpg';
import of_the from './images/of_the.svg';

import './RSVP.scss';

const emailRegex: RegExp = new RegExp(/\S+@\S+\.\S+/);

const emailValidator = (value: string) => emailRegex.test(value) ? "" : "Please enter a valid email.";
const firstNameGetter: any = getter("firstName");
const lastNameGetter: any = getter("lastName");
const emailGetter: any = getter("email");
const postalCodeGetter: any = getter("postalCode");

const formValidator = (values: any) => {
  if (!firstNameGetter(values)) {
    return {VALIDATION_SUMMARY: "First Name is required."};
  }
  
  if (!lastNameGetter(values)) {
    return {VALIDATION_SUMMARY: "Last Name is required."};
  }
  
  if (!emailRegex.test(emailGetter(values))) {
    return {VALIDATION_SUMMARY: "Please enter a valid email."};
  }

  if (!postalCodeGetter(values)) {
    return {VALIDATION_SUMMARY: "Postal Code is required."};
  }

  return;
};

const allowedTickets = 400;

function RSVP() {
  let navigate = useNavigate();

  useEffect(() => {
    // declare the data fetching function
    const fetchData = async () => {
      const total = await driveInFirebase.getCount();
      if (total > allowedTickets) {
        navigate('/', {replace: true});
      }
    }
  
    // call the function
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [])

  const handleSubmit = async (dataItem: { [name: string]: any }) => {
    const ticketCount = await driveInFirebase.getCount();

    if (ticketCount > allowedTickets) {
      alert('SOLD OUT!');
      navigate('/', {replace: true});
    }

    driveInFirebase.saveTicket({
      firstName: dataItem.firstName,
      lastName: dataItem.lastName,
      email: dataItem.email,
      postalCode: dataItem.postalCode,
      optIn: dataItem.optIn || false,
      videoOptIn: dataItem.videoOptIn,
      perVehicle: dataItem.perVehicle,
      createdAt: new Date(),
    }).then(() => {
      navigate('/thanks');
    });
  }

  return (
    <div className='wrapper'>
      <div className="content">
        <img src={ford_drive_in_logo} className="logo" alt="Ford Drive-In" />
        <div className="double-wide">
          <div className='poster-wrapper'>
            <img src={poster} width={'100%'} alt="Edge of the Unknown with Jimmy Chin" />
          </div>
          <div className='event-content'>
            <div className='blue-table'>
              EDGE <img src={of_the} alt="of the" className='and-home' /> UNKNOWN
            </div>

            <div className='blue-table'>
              WITH JIMMY CHIN
            </div>

            <div className='lightning-theater-text'>
              BRONCO&trade; THEATER
            </div>

            <div className='address'>
              1200 N. Spring Gulch Road <span className='no-wrap'>JACKSON, WY 83001</span><br />
            </div>
            <div className='time'>
              <div className='time-text'>
                AUG 20, 2022
              </div>
              <div className='arrow-wrapper'>
                <span className='arrow-right'></span>
              </div>
              <div className='time-text'>
                DOORS AT 5PM
              </div>
            </div>

            <div className='registration'>
              REGISTRATION
            </div>

            <Form
              onSubmit={debounce((data) => handleSubmit(data), 10000, {leading: true})}
              validator={formValidator}
              render={(formRenderProps: FormRenderProps) => (
                <FormElement>
                  <fieldset className={"k-form-fieldset"}>
                    {formRenderProps.submitted &&
                      formRenderProps.errors &&
                      formRenderProps.errors.VALIDATION_SUMMARY && (
                      <div className={"k-messagebox k-messagebox-error"}>
                        {formRenderProps.errors.VALIDATION_SUMMARY}
                      </div>
                    )}
                    <div className="double-wide no-margin">
                      <div className="form-wrapper">
                        <Field
                          className="form-input"
                          name={"firstName"}
                          component={Input}
                          label={"FIRST NAME"}
                          required={true}
                        />
                      </div>
                      <div className="form-wrapper2">
                        <Field
                          className="form-input"
                          name={"lastName"}
                          component={Input}
                          label={"LAST NAME"}
                          required={true}
                        />
                      </div>
                    </div>
                    <div className="double-wide no-margin">
                      <div className="form-wrapper">
                        <Field
                          className="form-input"
                          name={"email"}
                          type={"email"}
                          component={Input}
                          label={"EMAIL"}
                          validator={emailValidator}
                        />
                      </div>
                      <div className="form-wrapper2">
                        <Field
                          className="form-input"
                          name={"postalCode"}
                          component={MaskedTextBox}
                          mask="00000"
                          maxLength={5}
                          label={"POSTAL CODE"}
                          required={true}
                        />
                      </div>
                    </div>
                    <FieldWrapper>
                      <div className="form-checkbox">
                        <Field
                          component={Checkbox}
                          id="optIn"
                          name={"optIn"}
                          size="large"
                          rounded={null}
                          style={{ marginRight: 10, height: '30px', width: '30px' }}
                        />
                        <Label editorId='optIn'>YES, PLEASE EMAIL ME COMMUNICATIONS, INCLUDING PRODUCT INFORMATION, OFFERS AND INCENTIVES, FROM FORD MOTOR COMPANY AND THE LOCAL DEALER.</Label>
                      </div>
                    </FieldWrapper>
                    <FieldWrapper>
                      <div className="form-checkbox">
                        <Field
                          component={Checkbox}
                          id="videoOptIn"
                          name={"videoOptIn"}
                          size="large"
                          rounded={null}
                          style={{ marginRight: 10, height: '30px', width: '30px' }}
                          required={true}
                          validationMessage="You must agree to these terms to attend."
                        />
                        <Label editorId='videoOptIn'>I ACKNOWLEDGE AND AGREE, ON BEHALF OF MYSELF AND MY GUESTS, THAT I/WE MAY BE FILMED.</Label>
                      </div>
                    </FieldWrapper>
                    <FieldWrapper>
                      <div className="form-checkbox">
                        <Field
                          component={Checkbox}
                          id="perVehicle"
                          name={"perVehicle"}
                          size="large"
                          rounded={null}
                          style={{ marginRight: 10, height: '30px', width: '30px' }}
                          required={true}
                          validationMessage="You must agree to these terms to attend."
                        />
                        <Label editorId='perVehicle'>I ACKNOWLEDGE THAT REGISTRATION IS PER PERSON, NOT PER VEHICLE. ALL MEMBERS IN YOUR PARTY WILL NEED TO REGISTER INDIVIDUALLY BEFORE THE EVENT.</Label>
                      </div>
                    </FieldWrapper>
                  </fieldset>
                  <div className='legal'>
                  FORD MOTOR COMPANY RESPECTS YOUR PRIVACY AND TREATS YOUR PERSONAL INFORMATION WITH CARE. BY CLICKING "SUBMIT" YOU AGREE TO BE BOUND BY FORD MOTOR COMPANY'S PRIVACY POLICY, <a href="https://www.ford.com/help/privacy/" target={"_blank"} rel="noreferrer">AVAILABLE HERE</a>.
                  </div>
                  <div className="k-form-buttons">
                    <Button
                      type={"submit"}
                      className="submit"
                      disabled={!formRenderProps.allowSubmit}
                    >
                      SUBMIT
                    </Button>
                  </div>
                </FormElement>
              )}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RSVP;
